<template>
    <div class="layout">请选择风格</div>
</template>
<script>
export default {
}
</script>
<style scoped lang="scss">
@import './tpl.scss';
.layout{
    height: 100px;
}
</style>